<template>
    <div class="pt-6">
        <v-container fluid v-if="aktivitet">
            <v-row>
                <v-col cols="12" class="d-flex">
                    <h1 class="text-h4">
                        {{ aktivitet.tittel }}
                        <div v-if="type != 'SORGGRUPPE'" class="text-subtitle-1 grey--text text--darken-1">
                            <em>{{ maalgrupppe }}</em>
                        </div>
                    </h1>
                    <v-btn class="ml-2" color="primary" icon :to="routeView">
                        <v-icon>mdi-card-text</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-tabs v-model="tab" class="v-tabs--content">
                        <v-tab>Generelt</v-tab>
                        <v-tab v-if="type != 'SORGGRUPPE'">Målgruppe</v-tab>
                        <v-tab>Deltakere ({{ antallDeltakere }})</v-tab>
                        <v-tab v-if="type == 'SORGGRUPPE'">Rapport</v-tab>
                    </v-tabs>
                    <br />
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <tab-general v-model="aktivitet"></tab-general>
                        </v-tab-item>
                        <v-tab-item v-if="type != 'SORGGRUPPE'">
                            <tab-target v-model="aktivitet" type="aktivitet"></tab-target>
                        </v-tab-item>
                        <v-tab-item>
                            <tab-participants v-model="aktivitet" :type="type"></tab-participants>
                        </v-tab-item>
                        <v-tab-item v-if="type == 'SORGGRUPPE'">
                            <tab-summary v-model="aktivitet"></tab-summary>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TabTarget from '@/components/TabTarget';
import TabGeneral from '@/pages/activities/TabGeneral';
import TabParticipants from '@/pages/activities/TabParticipants';
import TabSummary from '@/pages/activities/TabSummary';

export default {
    name: 'Activity',
    components: { TabTarget, TabGeneral, TabParticipants, TabSummary },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tab: null,
            aktivitet: null,
        };
    },
    computed: {
        ...mapGetters('api', ['rolle']),
        type: function () {
            return this.aktivitet && this.aktivitet.type ? this.aktivitet.type.id : null;
        },
        routeView: function () {
            if (this.aktivitet) {
                if (this.aktivitet.type.id == 'SORGGRUPPE') {
                    return '/sorg/gruppemote/' + this.aktivitet.id;
                }
                return '/arrangement/' + this.aktivitet.id;
            }
            return null;
        },
        antallDeltakere: function () {
            let participants = 0;
            if (this.aktivitet && this.aktivitet.deltakere) {
                for (const participant of this.aktivitet.deltakere) {
                    if (!participant.fjernet) {
                        participants++;
                    }
                }
            }
            return participants;
        },
        maalgrupppe: function () {
            if (this.aktivitet && this.aktivitet.maalgrupper && this.aktivitet.maalgrupper.length) {
                let maalgruppper = [];
                for (const maalgruppe of this.aktivitet.maalgrupper) {
                    const rolle = (maalgruppe.rolle ? this.rolle(maalgruppe.rolle.id).flertall : 'alle').toLowerCase();
                    switch (maalgruppe.status) {
                        case 'AKTIV':
                            maalgruppper.push('aktive ' + rolle);
                            break;
                        case 'NY':
                            maalgruppper.push('nye ' + rolle);
                            break;
                        case 'PERMISJON':
                            maalgruppper.push(rolle + ' i permisjon');
                            break;
                        case 'SLUTTET':
                            maalgruppper.push(rolle + ' som har sluttet');
                            break;
                        default:
                            maalgruppper.push('alle ' + rolle);
                    }
                }
                maalgruppper = maalgruppper.join(', ');
                const lastIndex = maalgruppper.lastIndexOf(', ');
                if (lastIndex != -1) {
                    maalgruppper = maalgruppper.substring(0, lastIndex) + ' og ' + maalgruppper.substring(lastIndex + 1);
                }
                return 'For ' + maalgruppper + '.';
            }
            return 'For alle.';
        },
    },
    created: async function () {
        await this.load(this.id);

        const hash = location.hash ? location.hash.substring(1) : null;
        if (hash) {
            if (hash == 'rapport') {
                this.tab = 2;
            }
        }
    },
    methods: {
        ...mapActions('api', ['request', 'formatActivity']),

        /**
         * load
         */
        load: async function (id) {
            const response = await this.request({
                method: 'get',
                url: '/aktivitet/' + id,
            });

            if (response && typeof response.id != 'undefined') {
                await this.update(response);
            }
        },

        /**
         * update
         */
        update: async function (aktivitet) {
            this.aktivitet = await this.formatActivity(aktivitet);
        },
    },
};
</script>
