<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-data-table :headers="felter" :items="maalgrupper" no-data-text="Alle" :items-per-page="-1" hide-default-footer>
                <template v-slot:item.rolle="{ item }">
                    {{ item.rolle ? item.rolle.navn : 'Alle' }}
                </template>
                <template v-slot:item.status="{ item }">
                    {{ mapStatus(item.status) }}
                </template>
                <template v-slot:item.rediger="{ item }">
                    <div class="d-flex justify-center">
                        <v-icon small v-on:click="removeTarget(item)">mdi-delete</v-icon>
                    </div>
                </template>
                <template v-slot:foot>
                    <tfoot>
                        <tr>
                            <td style="height: auto">
                                <v-autocomplete
                                    label="Velg rolle"
                                    v-model="nyRolle"
                                    :items="alleRoller"
                                    item-text="navn"
                                    item-value="id"
                                    hide-details
                                ></v-autocomplete>
                            </td>
                            <td style="height: auto">
                                <v-autocomplete
                                    label="Velg status"
                                    v-model="nyStatus"
                                    :items="alleStatuser"
                                    item-text="navn"
                                    item-value="id"
                                    hide-details
                                ></v-autocomplete>
                            </td>
                            <td colspan="4" style="height: auto">
                                <v-btn outlined class="mt-4 ml-2" v-on:click="addTarget"> Legg til </v-btn>
                            </td>
                        </tr>
                    </tfoot>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
//import moment from 'moment';

export default {
    name: 'TabTarget',

    props: {
        type: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
        },
    },
    data() {
        return {
            objekt: {},
            maalgrupper: [],

            nyStatus: null,
            nyRolle: null,

            felter: [
                { text: 'Rolle', value: 'rolle', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: '', value: 'rediger', sortable: false, width: '130px' },
            ],
        };
    },
    computed: {
        ...mapGetters('api', ['roller', 'statuser']),
        alleRoller: function () {
            const roller = this.roller;
            roller.unshift({ id: '', navn: 'Alle' });
            return roller;
        },
        alleStatuser: function () {
            const statuser = this.statuser;
            statuser.unshift({ id: '', navn: 'Alle' });
            return statuser;
        },
    },

    /**
     * created
     */
    created: async function () {
        this.objekt = this.value;
        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatActivity', 'formatArticle', 'formatPerson']),
        ...mapActions(['snackbar']),

        /**
         * update
         */
        update: async function (objekt) {
            if (objekt) {
                if (this.type == 'aktivitet') {
                    this.objekt = await this.formatActivity(objekt);
                } else if (this.type == 'artikkel') {
                    this.objekt = await this.formatArticle(objekt);
                }
                this.$emit('input', objekt);
            }

            this.maalgrupper = this.objekt.maalgrupper ? this.objekt.maalgrupper : [];
        },

        /**
         * addTarget
         */
        addTarget: async function () {
            const maalgruppe = {};
            if (this.nyRolle) {
                maalgruppe.rolle = { id: this.nyRolle };
            }
            if (this.nyStatus) {
                maalgruppe.status = this.nyStatus;
            }

            const response = await this.request({
                method: 'post',
                url: '/' + this.type + '/' + this.objekt.id + '/maalgruppe',
                data: [maalgruppe],
            });
            if (!response) {
                console.log('TODO: error');
            } else {
                this.update(response);
                this.nyRolle = null;
                this.nyStatus = null;
            }
        },

        /**
         * removeTarget
         */
        removeTarget: async function (item) {
            const maalgruppe = {};
            if (item.rolle && item.rolle.id) {
                maalgruppe.rolle = { id: item.rolle.id };
            }
            if (item.status) {
                maalgruppe.status = item.status;
            }

            const response = await this.request({
                method: 'delete',
                url: '/' + this.type + '/' + this.objekt.id + '/maalgruppe',
                data: [maalgruppe],
            });
            if (!response) {
                console.log('TODO: error');
            } else {
                this.update(response);
                this.nyRolle = null;
                this.nyStatus = null;
            }
        },

        /**
         * mapStatus
         */
        mapStatus: function (status) {
            let found = this.statuser.find((s) => s.id == status);
            return found ? found.navn : 'Alle';
        },
    },
};
</script>
